import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import { debounce, classList } from '../../../utils';
import WancloudsLogo from '../../../images/icons/wanclouds-logo';
import ArrowIcon from '../../../images/icons/ArrowIcon';
import MobileHeader from './MobileHeader';
import Gen1Tab from "./Gen1Tab";

const isBrowser = typeof window !== 'undefined';

const TopBar = () => {
  const [isTop, setIsTop] = useState(true);

  let scrollableElement = isBrowser && document.querySelector('.scrollable-content');

  if (typeof window !== 'undefined') {
    if (!scrollableElement) scrollableElement = window;
  }

  let handleScrollRef = null;

  const handleScroll = () => {
    return debounce(({ target: { scrollTop } }) => {
      const isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
      setIsTop(isCurrentTop);
    }, 20);
  };

  handleScrollRef = handleScroll();

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScrollRef);
    }
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScrollRef);
      }
    };
  }, [scrollableElement, handleScrollRef]);
  return (
    <div className="landing">
      <Hidden only={['lg', 'xl', 'md', 'sm']}>
        <MobileHeader />
      </Hidden>
      <section
        className={classList({
          header: true,
          'header-fixed': !isTop,
        })}
      >
        <Gen1Tab />
        <Container className="mt-2 header-container">
          <div className="brand mt-2">
            <a href="http://wanclouds.net">
              <span style={{ cursor: 'pointer' }}>
                <WancloudsLogo color="#4977e5" width="125" />
              </span>
            </a>
          </div>
          <div className="m-auto" />
          <div className="navigation flex">
            <ul className="navigation ">
              <li>
                <div className="dropdown">
                  <Button
                    aria-haspopup="true"
                    className="font-light dropbtn text-black"
                  >
                    OFFERINGS
                    <ArrowIcon className="ml-1" style={{ fontSize: '7px', transform: 'rotate(90deg)' }} />
                  </Button>
                  <div className="dropdown-content">
                    <a href="https://wanclouds.net/draas">Disaster Recovery as a service</a>
                    <a href="https://wanclouds.net/maas">Migrations as a service</a>
                    <a href="https://wanclouds.net/services">Ongoing services</a>
                  </div>
                </div>
              </li>
              <li>
                <div className="dropdown">
                  <Button
                    aria-haspopup="true"
                    className="font-light dropbtn text-black"
                  >
                    PRODUCT
                    <ArrowIcon className="ml-1" style={{ fontSize: '7px', transform: 'rotate(90deg)' }} />
                  </Button>
                  <div className="dropdown-content">
                    <a href="https://wanclouds.net/vpc+">VPC +</a>
                  </div>
                </div>
              </li>
                <a href="http://docs.wanclouds.net">
                  <Button
                    aria-haspopup="true"
                    className="font-light text-black"
                  >
                    DOC & SUPPORT
                  </Button>
                </a>
              <a href="https://wanclouds.net/about-us">
                <Button
                  aria-haspopup="true"
                  className="font-light dropbtn text-black"
                >
                  ABOUT
                </Button>
              </a>
            </ul>
            <a href="https://wanclouds.net/contact-us">
              <Button
                className="box-shadow-none px-8 border-radius-8 ml-3 hover-bg-primary capitalize"
                variant="outlined"
                color="primary"
              >
                Contact Us
              </Button>
            </a>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default TopBar;
